/* eslint-disable no-unused-vars */
import { Box } from '@chakra-ui/react';

export default function Dashboard() {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
    >
      This is Dashboard Page. Don't forget login 😎😁
    </Box>
  );
}
